import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';

import { theme, GlobalStyle, AuthProvider, LoadingSpinner } from '@seriesfi/ui';
import { Provider } from 'react-redux';
import { store } from '@/api/store/store';
import Seo from '@/components/common/Seo';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <GlobalStyle />
        <AuthProvider>
          <Seo />
          <Component {...pageProps} />
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  );
}
